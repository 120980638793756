<template>
  <v-theme-provider light>
    <v-card
      v-if="editedItem.id > 0 || editedItem.id == -1"
      elevation="4"
      :key="kdoc"
    >
      <v-toolbar dense floating class="mt-n2 ml-n2" color="#73DDFF">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="classer"
              v-if="
                solder &&
                (editedItem.statut_id == '14' || editedItem.statut_id == '132')
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-cancel </v-icon>
              Classer
            </v-btn>
          </template>
          <span>Archiver</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="OpenPayeForm"
              v-if="editedItem.statut_id == '14' && dp_cat == 5"
              v-bind="attrs"
              v-on="on"
              ><v-icon left> mdi-list </v-icon>
              Paiements
            </v-btn>
          </template>
          <span>Paiements effectués </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="valider_dp"
              v-if="
                valider &&
                editedItem.statut_id == '13' &&
                dp_detail.length > 0 &&
                !edit
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-check-bold </v-icon>
              Valider
            </v-btn>
          </template>
          <span>Valider</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="valider_dp"
              v-if="
                accepter &&
                editedItem.statut_id == '131' &&
                dp_detail.length > 0 &&
                !edit &&
                dp_cat == 5
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-check-bold </v-icon>
              Accepter
            </v-btn>
          </template>
          <span>Accepter</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="reject_dp"
              v-if="
                accepter &&
                editedItem.statut_id == '131' &&
                dp_detail.length > 0 &&
                !edit &&
                dp_cat == 5
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-cancel </v-icon>
              Rejeter
            </v-btn>
          </template>
          <span>Rejeter</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="deleteDp"
              v-if="
                editer &&
                editedItem.statut_id == '13' &&
                dp_detail.length == 0 &&
                !edit
              "
              :disabled="editedItem.nbdocs > 0"
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-delete </v-icon>Supprimer</v-btn
            >
          </template>
          <span>Supprimer le document</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="modifier"
              v-if="editer && editedItem.statut_id == '13' && !edit"
              v-bind="attrs"
              v-on="on"
              ><v-icon left> mdi-pencil </v-icon>
              Modifier
            </v-btn>
          </template>
          <span>Editer pour modification</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="cancel"
              v-if="editer && editedItem.statut_id == '13' && edit"
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-close </v-icon> Annuler</v-btn
            >
          </template>
          <span>Annuler les Modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="save"
              v-if="editer && editedItem.statut_id == '13' && edit"
              v-bind="attrs"
              v-on="on"
              ><v-icon left> mdi-content-save-outline </v-icon>
              Enregistrer
            </v-btn>
          </template>
          <span>Enregistrer les modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="printdp"
              v-if="editedItem.statut_id != '13'"
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-printer </v-icon></v-btn
            >
          </template>
          <span>Imprimer demande de paiement </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                !edit &&
                ($store.state.auth.includes('01061') || $store.state.isadmin)
              "
              @click="OpenDocList"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-paperclip </v-icon>
            </v-btn>
          </template>
          <span>Documents Attachés</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="unlock"
              v-if="
                ($store.state.auth.includes('01068') || $store.state.isadmin) &&
                editedItem.ttc_paye == 0 &&
                (editedItem.statut_id == '14' ||
                  editedItem.statut_id == '131') &&
                !edit
              "
              v-bind="attrs"
              v-on="on"
              ><v-icon> mdi-lock-open-variant </v-icon>
            </v-btn>
          </template>
          <span>Déverrouiller </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                ($store.state.auth.includes('01060') || $store.state.isadmin)
              "
              @click.stop="drawer = !drawer"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-clipboard-text-clock-outline </v-icon>
            </v-btn>
          </template>
          <span>Historique Document</span>
        </v-tooltip>
        <v-progress-linear
          v-if="progress"
          indeterminate
          :size="30"
          :width="7"
          color="purple"
        ></v-progress-linear>
      </v-toolbar>
      <v-card class="px-4 scroll" max-height="850">
        <v-card-text>
          <v-row>
            <v-col cols="12" sd="7" md="7" align-self="start">
              <img :src="logo" alt="Company Logo" />
            </v-col>
            <v-col cols="12" sd="5" md="5">
              <v-alert
                v-model="alert"
                :type="type_alert"
                class="multi-line"
                elevation="4"
                >{{ message }}</v-alert
              >
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
            <v-row>
              <v-col cols="12" sd="6" md="6" lg="6">
                <v-row>
                  <v-col cols="12" sd="11" md="11">
                    <v-row>
                      <v-col cols="12" sm="6" md="10" v-if="edit && all">
                        <cursorselect
                          :Qsearch="tierscursor"
                          :Qresp="'tiers_cursor'"
                          :value="editedItem.tier_id"
                          :text_fields="['code_fournisseur', 'nom']"
                          :variableadd="variableadd"
                          :item_text="'tier_name'"
                          @change="tierChange"
                          :label="'Fournisseur'"
                          :key="cs"
                        >
                        </cursorselect>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="edit">
                        <v-select
                          dense
                          v-model="editedItem.dp_type"
                          :items="[
                            { id: '0', text: 'Régulière' },
                            { id: '1', text: 'Avance' },
                          ]"
                          item-text="text"
                          item-value="id"
                          label="Type DP"
                          :readonly="
                            !edit || facts_select.length > 0 || !editer
                          "
                          :rules="[(v) => !!v || 'Type DP obligatoire']"
                          @change="type_change"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="10" v-if="edit && !all">
                        <v-autocomplete
                          :items="tiers_list"
                          v-model="editedItem.tier_id"
                          :item-value="all ? 'id' : 'tier_id'"
                          :filter="customFilter"
                          :rules="[(v) => !!v || 'Fournisseur obligatoire']"
                          :label="'Fournisseur'"
                          @change="tierChange"
                          :readonly="
                            !modify || editedItem.dpdetails.length > 0 || !edit
                          "
                        >
                          <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{
                              data.item.code_tier +
                              "-" +
                              (data.item.tier_name
                                ? data.item.tier_name
                                : data.item.nom)
                            }}
                          </template>
                          <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{
                              data.item.code_tier +
                              "-" +
                              (data.item.tier_name
                                ? data.item.tier_name
                                : data.item.nom)
                            }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="2" v-if="edit">
                        <v-checkbox v-model="all" label="Tous"></v-checkbox>
                      </v-col> -->
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sd="2" md="4" class="text-left" v-if="!edit">
                    {{ dp_cat == 1 ? "Fournisseur" : "Client" }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    class="text-left h5"
                    v-if="!edit"
                  >
                    {{ editedItem.tier_name }}
                  </v-col>

                  <v-col cols="12" sd="2" md="4"> Code </v-col>
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.code_tier }}
                  </v-col>

                  <v-col cols="12" sd="2" md="4"> Adresse</v-col>
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.adresse }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                  >
                    R.C. N°</v-col
                  >
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.RC }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                    >N° Article</v-col
                  >
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.ART }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                    >Id Fiscal</v-col
                  >
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.NIF }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sd="2" md="6" lg="6">
                <v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sd="12" md="12">
                      <h3>{{ formTitle }}</h3>
                    </v-col>
                    <v-col cols="12" sd="12" md="12" lg="12" v-if="!edit">
                      <strong>
                        Type :
                        {{
                          editedItem.dp_type == "0" ? "Régulière" : "Avance"
                        }}</strong
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sd="12"
                      md="12"
                      lg="12"
                      v-if="!edit && editedItem.commande"
                    >
                      <strong>
                        Commande N° :
                        {{
                          editedItem.commande
                            ? editedItem.commande.nocmd +
                              (editedItem.commande.label
                                ? "-" + editedItem.commande.label
                                : "")
                            : ""
                        }}</strong
                      >
                    </v-col>

                    <v-col cols="12" sd="12" md="12" lg="12">
                      <strong> Date : {{ datefr(editedItem.dp_date) }}</strong>
                    </v-col>
                    <v-col cols="12" sd="12" md="12" lg="12">
                      <strong v-if="!edit">
                        Ref. : {{ editedItem.dp_ref }}</strong
                      >
                    </v-col>
                  </v-row>
                  <v-col cols="12" sd="12" md="12" lg="12">
                    <v-row dense v-if="edit">
                      <v-col cols="12" sd="6" md="6" class="text-left">
                        <v-text-field
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          v-model="editedItem.dp_ref"
                          :readonly="!edit"
                          label="Ref."
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        v-if="editedItem.dp_type == '1'"
                      >
                        <v-autocomplete
                          v-model="editedItem.cmd_id"
                          :items="cmds_tier"
                          :item-text="
                            (item) =>
                              item.nocmd +
                              (item.cmd_label
                                ? ' (' + item.cmd_label + ') '
                                : '') +
                              ' du ' +
                              datefr(item.date_cmd, false, false)
                          "
                          item-value="id"
                          :label="'Commande'"
                          :readonly="!edit"
                          small-chips
                          :rules="[(v) => !!v || 'Commande obligatoire']"
                          @change="cmd_change"
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        v-if="editedItem.dp_type == '0'"
                      >
                        <v-autocomplete
                          v-model="facts_select"
                          :items="facts_tier"
                          :item-text="
                            (item) =>
                              (item.fact_ref ? item.fact_ref : item.nofact) +
                              ' du ' +
                              datefr(item.fact_date, false, false) +
                              ' Montant: ' +
                              numberWithSpace(
                                item.mont_ttc
                                  ? item.mont_ttc.toFixed(2)
                                  : '0.00'
                              )
                          "
                          item-value="id"
                          :label="'Factures'"
                          :rules="[(v) => !!v || 'Factures obligatoire']"
                          :readonly="!edit"
                          multiple
                          small-chips
                          dense
                          clearable
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        v-if="editedItem.dp_type == '0' && avcs.length > 0"
                      >
                        <v-autocomplete
                          v-model="avcs_select"
                          :items="avcs"
                          :item-text="
                            (item) =>
                              item.nodp +
                              ' du ' +
                              datefr(item.dp_date, false, false) +
                              ' Montant: ' +
                              numberWithSpace(
                                item.mont_ttc
                                  ? item.mont_ttc.toFixed(2)
                                  : '0.00'
                              )
                          "
                          item-value="id"
                          :label="'Avances'"
                          :rules="[
                            (v) =>
                              avcs_select.length > 0 || 'Avances obligatoire',
                          ]"
                          :readonly="!edit"
                          multiple
                          small-chips
                          dense
                          clearable
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="12" v-if="rejects.length > 0">
                        <v-autocomplete
                          v-model="rejected_select"
                          :items="rejects"
                          :item-text="
                            (item) =>
                              item.nodp +
                              ' du ' +
                              datefr(item.dp_date, false, false) +
                              ' Montant: ' +
                              numberWithSpace(
                                item.mont_ttc
                                  ? item.mont_ttc.toFixed(2)
                                  : '0.00'
                              )
                          "
                          item-value="id"
                          :label="'DPs Rejetées'"
                          :rules="[
                            (v) =>
                              rejected_select.length > 0 ||
                              'DP Rejetée obligatoire',
                          ]"
                          :readonly="!edit"
                          multiple
                          small-chips
                          dense
                          clearable
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" md="4">
                        <v-select
                          dense
                          v-model="editedItem.modepaiement"
                          :items="modes_list"
                          item-text="mode"
                          item-value="id"
                          label="Mode Paiement"
                          :readonly="
                            !edit ||
                            !editer ||
                            (editedItem.paye_statut == '1' &&
                              editedItem.statut_id > '13')
                          "
                          :rules="[
                            (v) => !!v || 'Mode de paiement obligatoire',
                          ]"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sd="4" md="7" class="text-left">
                        <v-row>
                          <v-col cols="12" sd="6" md="9">
                            <datepicker
                              label="
                          Délai de Paiement
                        "
                              v-model="duedate"
                              :edit="edit"
                              @change="nbjour"
                              :key="kdd"
                              :date_max="null"
                              :date_min="editedItem.dp_date"
                            ></datepicker>
                          </v-col>
                          <v-col cols="12" sd="6" md="3">
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              dense
                              class="inputPrice"
                              v-model.number="editedItem.delai_paye"
                              :label="'Jours'"
                              :readonly="
                                !editer ||
                                editedItem.paye_statut == '1' ||
                                !edit
                              "
                              @input="addDays"
                              hide-spin-buttons
                              @focus="$event.target.select()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-text-field
                          dense
                          v-model="editedItem.note"
                          label="Note"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-text-field
                          dense
                          v-model="editedItem.comment"
                          label="Commentaire"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col cols="12" sd="12" md="12" v-if="!edit">
              <listitems
                :list="dp_detail"
                :title="'Items'"
                :headers="fact_headers"
                :filename="'Items'"
                :sheetname="'Items'"
                :master="true"
                :showedit="false"
                :key="klist"
                :add="
                  editer &&
                  editedItem.dp_type == '1' &&
                  dp.statut_id == '13' &&
                  dp.id > 0
                "
                :Update="true"
                :del="
                  editer &&
                  editedItem.dp_type == '1' &&
                  dp.statut_id == '13' &&
                  dp.id > 0
                "
                :del_disable="dp.fact_id"
                :elevation="0"
                @open="OpenDetailForm"
                @rowselect="DetailSelect"
                @delete="deleteItem"
              >
              </listitems>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="!edit">
            <v-col cols="12" sd="6" md="7">
              <v-row no-gutters>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  DELAI DE PAIEMENT

                  {{
                    editedItem.delai_paye
                      ? editedItem.delai_paye +
                        (parseInt(editedItem.delai_paye) != "NAN"
                          ? " jours"
                          : "")
                      : ""
                  }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left r">
                  MODE DE PAIEMENT :
                  {{ mode }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  NB : {{ editedItem.note }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Comment : {{ editedItem.comment }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Etablit Par : {{ editedItem.contact }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sd="6" md="4">
              <v-row v-if="editedItem.dp_type == '0'" no-gutters>
                <v-col cols="12" sd="6" md="4"> Total H.T </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                >
                  {{ numberWithSpace(subTotal) }}
                </v-col>
              </v-row>

              <v-row v-if="editedItem.dp_type == '0'" no-gutters>
                <v-col cols="12" sd="6" md="4">T.V.A </v-col>
                <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                  {{ numberWithSpace(taxTotal) }}
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sd="6" md="4"> Total TTC</v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold text-right pa-1"
                >
                  {{ numberWithSpace(grandTotal) }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <template>
            <confirmdialog
              ref="confirm"
              :is_motif="is_motif"
              @motif="get_motif"
            />
          </template>

          <v-snackbar
            v-model="snackbar"
            :timeout="snackbar_timeout"
            top
            :color="snackbar_color"
          >
            {{ snackbar_text }}
          </v-snackbar>

          <factpayelist
            :dp="editedItem"
            :showpaye="showpaye"
            :paye_list="paye_list"
            @add="add_paye"
            :add="
              editedItem.paye_statut == '0' &&
              editedItem.statut_id == '14' &&
              ($store.state.auth.includes('02016') || $store.state.isadmin)
            "
            :key="fp"
            @close="ClosePayeForm"
          >
          </factpayelist>
          <dpdetailform
            :item="dpdetail"
            :items="dp_detail"
            :key="dkey"
            :showForm="!isDetailClosed"
            :qte_max="editedItem.mont_avance"
            @close="closeDetailForm"
          >
          </dpdetailform>
          <filelist
            :item="editedItem"
            :showform="!isDocListClose"
            :key="fdocl"
            @close="isDocListClose = true"
            :editer="editer"
            :doc_type="4"
            :auth="auth"
          >
          </filelist>
          <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            right
            width="600"
          >
            <v-card flat>
              <v-card-title>
                <v-toolbar color="grey lighten-2" flat>
                  <v-toolbar-title> Historique du Document </v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="(item, i) in operations"
                    :key="i"
                    fill-dot
                    small
                    :color="item.color"
                    :icon="item.icon"
                  >
                    <strong>{{ item.label }}</strong>
                    {{
                      " Par " +
                      item.user_name +
                      " le " +
                      datefr(item.created_at, true)
                    }}
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-navigation-drawer>
        </v-card-text>
      </v-card>
    </v-card>
  </v-theme-provider>
</template>
<script>
//const cursorselect = () => import("./CursorSelect.vue");

import DELETE_DP from "../graphql/DP/DELETE_DP.gql";
import UPDATE_DP from "../graphql/DP/UPDATE_DP.gql";
import DELETE_DP_DETAIL from "../graphql/DP/DELETE_DP_DETAIL.gql";
import CREATE_DP from "../graphql/DP/CREATE_DP.gql";
import UPDATE_DETAIL_DP from "../graphql/DP/UPDATE_DETAIL_DP.gql";
import UPDATE_MODIF from "../graphql/UPDATE_MODIF.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";

import { doc_template } from "print/template.js";
import { datefr } from "@/utils/functions.js";
import { myLogo, myName, myIF, myRC, myART } from "print/data.js";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    factpayelist: () => import("./FactPayeList.vue"),
    filelist: () => import("./FileList.vue"),
    listitems: () => import("./ListItems.vue"),
    dpdetailform: () => import("./DpDetailForm.vue"),
    datepicker: () => import("./DatePicker.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
  },
  props: {
    dp: Object,
    dp_cat: Number,
    editer: Boolean,
    valider: Boolean,
    solder: Boolean,
    accepter: Boolean,
    modes: Array,
    facts_list: Array,
    cmds_list: Array,
    avances_list: Array,
    rejected_list: Array,
    edition: { type: Boolean, default: false },
  },
  data() {
    return {
      all: false,
      drawer: null,
      alltier: false,
      modify: false,
      paye_bl: false,
      progress: false,
      loading: false,
      duedate: null,
      valid: true,
      is_motif: false,
      cs: 1200,
      numberRule: (v) => {
        if (!v || (!isNaN(parseFloat(v)) && v >= 0 && v <= 100)) return true;
        return "Nombre doit être entre 0 and 100";
      },
      act_save: true,
      isDetailClosed: true,
      isDocListClose: true,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      dpdetail: {},
      message: "",
      alert: false,
      type_alert: "error",
      mc: 200,
      edit: false,
      dkey: 100,
      kdd: 300,
      pkey: 400,
      fdocl: 1000,
      showpaye: false,
      changed: false,
      avcs: [],
      rejects: [],
      fact_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          align: "left",
          width: "140",
        },
        {
          text: "Designation",
          value: "label",
          slot: "href99",
          column: "cmd_id",
          selected: true,
          align: "left",
        },
        {
          text: "HT",
          value: "mont_ht",
          slot: "cur",
          align: "end",
          hiden: true,
          selected: true,
          width: "150",
        },
        {
          text: "TVA",
          value: "mont_tva",
          slot: "cur",
          align: "end",
          hiden: true,
          selected: true,
          width: "150",
        },
        {
          text: "Montant",
          value: "mont_ttc",
          slot: "cur",
          align: "end",
          selected: true,
          width: "150",
        },
      ],
      editedItem: {},
      cmd_list: [],
      facts_select: [],
      avcs_select: [],
      rejected_select: [],
      modes_list: [],
      klist: 500,
      kdoc: 700,
      fp: 300,
      cmpt: 0,
      defaultItem: {
        id: -1,
        status_name: "Brouillon",
        statut_id: "13",
        dp_date: null,
        commande: {},
        dpdetails: [],
        docs: [],
        paye_statut: "0",
        dp_type: "0",
      },
      cmds: [],
      adresses: [],
      payes_list: [],
    };
  },

  async created() {
    if (this.dp)
      if (this.dp.id) {
        this.edit = this.edition;
        if (this.dp.id > 0) {
          this.editedItem = Object.assign({}, this.dp);
          this.cs++;
          if (
            this.editedItem.statut_id != "13" ||
            this.editedItem.dp_type == "0"
          ) {
            this.fact_headers[2].slot = null;
          }

          this.fact_headers[3].hiden = this.editedItem.dp_type == "1";
          this.fact_headers[4].hiden = this.editedItem.dp_type == "1";

          let i = this.tiers_list.findIndex(
            (elm) => elm.tier_id == this.editedItem.tier_id
          );

          if (i == -1) this.all = true;
          this.addDays();
          this.cmd_change();
          this.facts_select = [];
          this.avcs_select = [];
          this.rejected_select = [];
          if (this.editedItem.dp_type == "0") {
            if (this.editedItem.dpdetails)
              this.facts_select = this.editedItem.dpdetails.map(
                (elm) => elm.fact_id
              );
            if (this.editedItem.avances)
              this.avcs_select = this.editedItem.avances.map((elm) => elm.id);
          }
          if (this.editedItem.rejects)
            this.rejected_select = this.editedItem.rejects.map((elm) => elm.id);
          if (this.editedItem.statut_id == "132") {
            this.message =
              "Rejetée pour motif : " +
              (this.editedItem.motif_rejet ? this.editedItem.motif_rejet : "");
            this.alert = true;
          }
          if (this.editedItem.statut_id == "15") {
            this.message =
              "Classée pour motif : " +
              (this.editedItem.motif ? this.editedItem.motif : "");
            this.alert = true;
          }
          this.modes_list = this.modes.filter(
            (elm) => elm.type == (this.editedItem.country_id == 13 ? 0 : 1)
          );
        } else {
          this.defaultItem.dp_date = this.$store.state.today;
          this.editedItem = Object.assign({}, this.defaultItem);

          if (this.dp.tier_id > 0) {
            this.editedItem.tier_id = this.dp.tier_id;
            this.tierChange(this.editedItem.tier_id);
            this.editedItem.cmd_id = this.dp.cmd_id;
            this.cmd_change();
            this.editedItem.modepaiement = this.dp.modepaiement;
            this.editedItem.delai_paye = this.dp.delai_paye;
            this.facts_select = [];
            this.facts_select.push(this.dp.fact_id);
          }
        }
      }
    this.modify = this.editer && this.editedItem.statut_id == "13";
  },

  methods: {
    customFilter(item, queryText) {
      let textlist = [];
      ["code_fournisseur", "tier_name", "matricule"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    type_change() {
      this.editedItem.cmd_id = null;

      this.facts_select = [];
      this.avcs_select = [];
      this.rejected_select = [];
      if (
        this.cmds_tier.length == 1 &&
        this.editedItem.dp_type == "1" &&
        this.editedItem.id < 0
      )
        this.editedItem.cmd_id = this.cmds_tier[0].id;
      if (
        this.facts_tier.length == 1 &&
        this.editedItem.dp_type == "0" &&
        this.editedItem.id < 0
      )
        this.facts_select.push(this.facts_tier[0].id);
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    async deleteItem(item, index) {
      if (await this.verif_modif("d")) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cet element?"
          )
        ) {
          let v = {
            id: item.id,
          };
          await this.maj(DELETE_DP_DETAIL, v, true);
          this.dp_detail.splice(index, 1);
          this.$store.dispatch("Editing", false);
        } else this.update_modif();
      }
    },
    OpenDocList() {
      this.isDocListClose = false;
      this.fdocl++;
    },
    get_motif(m) {
      this.editedItem.motif = m;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    OpenPayeForm() {
      this.fp++;
      this.showpaye = true;
    },

    async updateDetailDp(dp) {
      let v = {
        fact_list: this.facts_select,
        avc_list: this.avcs_select,
        reject_list: this.rejected_select,
        dp_id: this.editedItem.id,
        dp: dp,
        write_uid: this.$store.state.me.id,
      };
      await this.maj(UPDATE_DETAIL_DP, v);
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "";
    },

    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },
    async save() {
      if (this.$refs.form ? this.$refs.form.validate() : true) {
        this.save_disable = true;

        if (
          (!this.editedItem.RC ||
            !this.editedItem.NIF ||
            !this.editedItem.ART) &&
          this.editedItem.country_id == "13"
        ) {
          this.snackbar_text = "identite fiscale du tier incomplete!";
          this.snackbar_color = "error";
          this.snackbar = true;
        } else {
          if (this.editedItem.id > 0) {
            let v = {
              dp: {
                id: this.editedItem.id,
                dp_ref: this.editedItem.dp_ref,
                tier_id: this.editedItem.tier_id,
                cmd_id: this.editedItem.cmd_id,
                dp_date: this.editedItem.dp_date,
                comment: this.editedItem.comment,
                statut_id: this.editedItem.statut_id,
                delai_paye: this.editedItem.delai_paye,
                note: this.editedItem.note,
                modepaiement: this.editedItem.modepaiement,
                dp_type: this.editedItem.dp_type,

                modif_by: null,
                modif_date: null,

                write_uid: this.$store.state.me.id,
              },
            };

            this.$store.dispatch("Editing", false);
            if (this.editedItem.statut_id == "13") await this.updateDetailDp(0);
            await this.maj(UPDATE_DP, v, true);
          } else {
            this.editedItem.date_opr = this.editedItem.dp_date;
            let v = {
              dp: {
                dp_type: this.editedItem.dp_type,
                dp_ref: this.editedItem.dp_ref,
                tier_id: this.editedItem.tier_id,
                cmd_id: this.editedItem.cmd_id,
                dp_date: this.editedItem.dp_date,
                delai_paye: this.editedItem.delai_paye,
                note: this.editedItem.note,
                modepaiement: this.editedItem.modepaiement,
                statut_id: this.editedItem.statut_id,
                date_opr: this.editedItem.date_opr,
                comment: this.editedItem.comment,

                create_uid: parseInt(this.$store.state.me.id),
                write_uid: parseInt(this.$store.state.me.id),
              },
            };
            let r = await this.maj(CREATE_DP, v);
            if (r) {
              this.editedItem.id = r.createDP.id;
              this.$store.dispatch("Changed", true);
              this.$store.dispatch("Editing", false);
              this.updateDetailDp(0);
              this.$route.params.fact = null;
              this.$emit("change", this.editedItem);
            }
          }
          this.save_disable = false;
          this.edit = false;
          this.$emit("edition", false);
          this.kdoc++;
        }
      }
    },
    async unlock() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir déverrouiller cette demande de paiement?"
        )
      ) {
        this.editedItem.statut_id = 13;
        let v = {
          dp: {
            id: this.editedItem.id,
            statut_id: this.editedItem.statut_id,
            date_opr: this.$store.state.today,
            mont_ht: null,
            mont_tva: null,
            mont_ttc: null,

            modif_by: null,
            modif_date: null,

            write_uid: this.$store.state.me.id,
          },
        };

        await this.updateDetailDp(0);
        this.$store.dispatch("Editing", false);
        await this.maj(UPDATE_DP, v, true);
        this.$emit("change", this.editedItem);
      }
    },
    async classer() {
      if (await this.verif_modif("c")) {
        this.is_motif = true;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir classer cette demande de paiement?"
          )
        ) {
          this.editedItem.statut_id = 15;
          let v = {
            dp: {
              id: this.editedItem.id,
              statut_id: this.editedItem.statut_id,
              motif: this.editedItem.motif,
              date_opr: this.$store.state.today,
              modif_by: null,
              modif_date: null,
              write_uid: this.$store.state.me.id,
            },
          };
          this.$store.dispatch("Editing", false);
          await this.maj(UPDATE_DP, v, true);
          this.$emit("change", this.editedItem);
        }
      }
    },
    async reject_dp() {
      this.is_motif = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir rejeter cette demande de paiement?"
        )
      ) {
        this.editedItem.statut_id = 132;
        let v = {
          dp: {
            id: this.editedItem.id,
            statut_id: this.editedItem.statut_id,
            rejected: 1,
            motif_rejet: this.editedItem.motif,
            write_uid: this.$store.state.me.id,
          },
        };
        await this.updateDetailDp(0);
        await this.maj(UPDATE_DP, v, true);
        this.$emit("refresh", this.editedItem);
        this.$emit("change", this.editedItem);
        this.is_motif = false;
      } else this.is_motif = false;
    },
    async valider_dp() {
      if (await this.verif_modif("v")) {
        if (this.$refs.form.validate()) {
          if (
            this.editedItem.statut_id == 13 &&
            this.editedItem.modepaiement == "4" &&
            this.ttc > 100000
          ) {
            this.snackbar_text =
              "Montant TTC superieur à 100 000.00 DA pour le mode réglement en espéce!";
            this.snackbar_color = "error";
            this.snackbar = true;
          } else if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Etês-vous sûr de vouloir " +
                (this.editedItem.statut_id == 13 ? "valider" : "accepter") +
                " cette demande de paiement?"
            )
          ) {
            let old = this.editedItem.statut_id;
            this.editedItem.statut_id =
              this.editedItem.statut_id == 13 ? 131 : 14;

            let v = {
              dp: {
                id: this.editedItem.id,
                statut_id: this.editedItem.statut_id,
                dp_date: this.$store.state.today,
                date_opr: this.editedItem.dp_date,
                mont_ht: parseFloat(this.subTotal),
                mont_tva: parseFloat(this.taxTotal),
                mont_ttc: parseFloat(this.grandTotal),
                motif_rejet: this.editedItem.motif,
                modif_by: null,
                modif_date: null,
                write_uid: this.$store.state.me.id,
              },
            };

            if (old == 13) this.updateDetailDp(1);

            this.$store.dispatch("Editing", false);
            await this.maj(UPDATE_DP, v, true);
            this.$emit("refresh", this.editedItem);
            this.$emit("change", this.editedItem);
          } else this.update_modif();
        }
      }
    },

    async modifier() {
      if (await this.verif_modif("m")) {
        this.edit = true;
        this.$emit("edition", true);
        this.kdoc++;
      }
    },
    async cancel() {
      let ok = true;
      if (this.cmpt > 2) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir annuler?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        //goto mouvments

        if (this.$route.params.fact) {
          await this.update_modif();
          this.$router.push({
            name: this.$route.params.name,
            params: {
              fact: this.$route.params.fact,
            },
          });
        } else {
          if (this.editedItem.id < 0) {
            this.$emit("cancel", this.editedItem);
            this.editedItem.id = null;
          }
          this.edit = false;
          this.$emit("edition", false);
          await this.update_modif();
          this.kdoc++;
          this.$store.dispatch("Changed", true);
        }
      }
    },
    async deleteDp() {
      if (await this.verif_modif("d")) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cette demande de paiement?"
          )
        ) {
          let v = {
            id: this.editedItem.id,
          };
          await this.maj(DELETE_DP, v, true);
          this.editedItem = { id: null };
          this.$store.dispatch("Editing", false);
        } else this.update_modif();
      }
    },

    async verif_modif(e) {
      this.alert = false;
      let ok = false;
      let v = {
        table: "dps",
        statut_id: "13",
        id: this.editedItem.id,
      };
      this.progress = true;
      let r = await this.maj(UPDATE_MODIF, v);
      this.progress = false;
      if (r) {
        let d = JSON.parse(r.UpdateModif);
        if (d.modif == 1) {
          this.alert = true;
          this.message =
            "Document ouvert en modification par " +
            d.name +
            " depuis le " +
            datefr(d.modif_date, true);
          this.type_alert = "warning";
        } else if (d.modif == 2 && e == "m") {
          this.alert = true;
          this.message = "Document Validé!! Actualiser vos données ";
          this.type_alert = "error";
        } else if (d.modif == -1) {
          this.alert = true;
          this.message = "Document introuvable ";
          this.type_alert = "error";
        } else {
          this.$store.dispatch("Document", v);
          this.$store.dispatch("Editing", true);
          ok = true;
        }
      }
      return ok;
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.editedItem.id > 0) {
        let v = {
          dp: {
            id: this.editedItem.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_DP, v);
      }
    },
    printdp() {
      let doctype = [];
      doctype.push({
        lib: {
          content: "Demande de paiement",
          styles: { fontStyle: "bold", fontSize: 20, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      doctype.push({
        lib: {
          content: "N° :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: this.editedItem.nodp,
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Date : ",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: datefr(this.editedItem.dp_date),
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Réference:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.cmd_ref ? this.editedItem.cmd_ref : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Mode de Réglement:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.mode ? this.mode : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });

      let tier = [];
      tier.push({
        lib: {
          content: "Béneficiaire",
          styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: this.editedItem.tier_name,
          styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: this.editedItem.adresse ? this.editedItem.adresse : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: "Tél :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.tel ? this.editedItem.tel : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "Email :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.email ? this.editedItem.email : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "RC  :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.RC ? this.editedItem.RC : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "NIF  :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.NIF ? this.editedItem.NIF : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "ART  :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.ART ? this.editedItem.ART : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      let foot = [];
      if (this.editedItem.dp_type == "0") {
        foot.push({
          libelle: "Montant HT",
          value: this.numberWithSpace(this.subTotal),
        });
        foot.push({
          libelle: "TVA ",
          value: this.numberWithSpace(this.taxTotal),
        });
      }
      foot.push({
        libelle: "Montant TTC",
        value: {
          content: this.numberWithSpace(this.grandTotal),
          styles: { fontStyle: "bold" },
        },
      });

      let detailheaders = this.fact_headers;
      let lettre = [];
      lettre.push({
        libelle: {
          content: "Arrêtée la présente demande de paiement à la somme de :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
      });
      lettre.push({
        libelle: {
          content: this.$lettre.NumberToLetter(this.grandTotal),
          styles: { halign: "left", fontStyle: "normal", fontSize: 10 },
        },
      });
      doc_template(
        this.$store,
        detailheaders,
        this.dp_detail,
        doctype,
        tier,
        foot,
        lettre,
        this.editedItem.note,
        "Demande de paiement",
        null,
        false
      );

      this.loading = false;
    },
    add_paye() {
      this.editedItem.dp = "1";
      this.showpaye = false;
      this.$router.push({
        name: "paiements",
        params: { document: this.editedItem, name: "dps_compta" },
      });
    },

    cmd_change() {
      this.facts_select = [];
      this.avcs_select = [];
      this.rejected_select = [];

      if (this.editedItem.cmd_id) {
        this.avcs = this.avances_list.filter(
          (elm) => elm.cmd_id == this.editedItem.cmd_id
        );
        this.rejects = this.rejected_list.filter(
          (elm) => elm.cmd_id == this.editedItem.cmd_id
        );
      }

      if (
        this.avcs.length == 1 &&
        this.editedItem.dp_type == "0" &&
        this.editedItem.id < 0
      )
        this.avcs_select.push(this.avcs[0].id);
      if (
        this.rejects.length == 1 &&
        this.editedItem.dp_type == "0" &&
        this.editedItem.id < 0
      ) {
        this.rejected_select.push(this.rejects[0].id);
        if (!this.editedItem.note)
          this.editedItem.note =
            "Annule et remplace la DP N° " + this.rejects[0].nodp;
      }

      let i = this.cmds_tier.findIndex(
        (elm) => elm.id == this.editedItem.cmd_id
      );

      if (i >= 0) {
        let a =
          ((this.cmds_tier[i].avance ? this.cmds_tier[i].avance : 0) *
            this.cmds_tier[i].mont_ttc) /
          100;
        if (a == 0) this.editedItem.mont_avance = this.cmds_tier[i].mont_rest;
        else
          this.editedItem.mont_avance =
            this.cmds_tier[i].mont_rest < a ? this.cmds_tier[i].mont_rest : a;
        this.editedItem.delai_paye =
          this.cmds_tier[i].delai_paye > 0
            ? this.cmds_tier[i].delai_paye
            : null;
      }
      this.cmpt++;
    },
    tierChange(item, l) {
      let tier;
      let list = this.tiers_list;
      if (l) list = l;
      this.editedItem.tier_id = item;
      this.editedItem.cmd_id = null;
      if (this.cmds_tier.length == 1)
        this.editedItem.cmd_id = this.cmds_tier[0].id;
      this.cmd_change();
      let i = list.findIndex(
        (elm) => (this.all ? elm.id : elm.tier_id) == this.editedItem.tier_id
      );
      this.modes_list = [];
      if (i >= 0) {
        tier = list[i];
        this.editedItem.modepaiement = tier.modepaiement;
        this.editedItem.delai_paye = tier.delai_paye;
        this.editedItem.code_tier = tier.code_tier;
        this.editedItem.adresse = tier.adresse;
        this.editedItem.RC = tier.RC;
        this.editedItem.NIF = tier.NIF;
        this.editedItem.ART = tier.ART;
        this.editedItem.country_id = tier.country_id;
        if (this.editedItem.country_id != 13 && this.editedItem.id < 0)
          this.editedItem.dp_type = "1";
        this.modes_list = this.modes.filter(
          (elm) => elm.type == (this.editedItem.country_id == 13 ? 0 : 1)
        );
      }
      this.cmpt++;
    },

    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },

    ClosePayeForm() {
      this.showpaye = false;
    },

    nbjour() {
      if (this.editedItem.dp_date && this.duedate) {
        var date2 = new Date(this.duedate);
        var date1 = new Date(this.editedItem.dp_date);
        var diff = date2.getTime() - date1.getTime();
        this.editedItem.delai_paye = Math.floor(diff / (24 * 3600 * 1000));
        this.cmpt++;
      } else this.editedItem.delai_paye = null;
    },
    addDays() {
      if (this.editedItem.delai_paye) {
        let d = parseInt(this.editedItem.delai_paye);
        var result = new Date(this.editedItem.dp_date);
        result.setDate(result.getDate() + d);
        this.duedate = result.toISOString().substr(0, 10);
        this.kdd++;
      } else this.duedate = null;
      this.cmpt++;
    },
    datefr(date, time = false, long = true) {
      let t = "";
      let option = {
        year: "numeric",
        month: long ? "long" : "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    DetailSelect(item) {
      this.dpdetail = item;
    },
    async OpenDetailForm(item) {
      if (await this.verif_modif("m")) {
        this.dpdetail = item;
        this.dpdetail.dp_id = this.editedItem.id;
        if (item.id < 0) {
          if (this.editedItem.country_id == 13)
            this.dpdetail.label =
              "Avance sur commande N° " + this.editedItem.commande.nocmd;
          this.dpdetail.mont_ttc = this.editedItem.mont_avance;
        }

        this.dkey++;
        this.isDetailClosed = false;
      }
    },
    closeDetailForm() {
      this.update_modif();
      this.isDetailClosed = true;
    },
  },

  computed: {
    variableadd() {
      let w = {
        TypeScope: 1,
        statut_id: [2, 3],
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    auth() {
      let a = "01083";

      return a;
    },

    tiers_list() {
      let list = [];
      if (this.editedItem.dp_type == 0) {
        if (this.facts_list) list = this.facts_list;
      } else {
        if (this.cmds_list) list = this.cmds_list;
      }

      return list;
    },
    cmds_tier() {
      let list = [];
      if (this.editedItem.tier_id)
        list = this.cmds_list.filter(
          (elm) => elm.tier_id == this.editedItem.tier_id
        );
      return list;
    },
    facts_tier() {
      let list = [];
      if (this.editedItem.tier_id)
        list = this.facts_list.filter(
          (elm) => elm.tier_id == this.editedItem.tier_id
        );
      return list;
    },
    paye_list: function () {
      return this.editedItem.id > 0 ? this.editedItem.paiements : [];
    },
    existe_remise() {
      let i = this.detail_list.findIndex((elm) => elm.remise > 0);
      return i >= 0;
    },

    formTitle() {
      return this.editedItem.id < 0
        ? "Nouvelle demande de paiement"
        : "Demande de paiement" +
            (this.editedItem.nodp ? " N° " + this.editedItem.nodp : "");
    },
    logo() {
      return myLogo;
    },
    myname() {
      return myName;
    },

    mode() {
      let i = this.modes.findIndex(
        (elm) => elm.id == this.editedItem.modepaiement
      );
      let modepaiement = "";
      if (i >= 0) modepaiement = this.modes[i].mode;
      return modepaiement;
    },
    myrc() {
      return myRC;
    },
    myif() {
      return myIF;
    },
    myart() {
      return myART;
    },

    operations: function () {
      return this.editedItem.id > 0 ? this.editedItem.operations : [];
    },

    detail_list: function () {
      return this.editedItem.id > 0 ? this.editedItem.dpdetails : [];
    },

    avances: function () {
      return this.editedItem.avances ? this.editedItem.avances : [];
    },
    dp_detail() {
      let items = [];
      this.detail_list.forEach((element) => {
        items.push(element);
      });
      this.avances.forEach((element) => {
        items.push({
          id: element.id,
          code: element.nodp,
          label:
            "DP Avance N° " +
            element.nodp +
            " du " +
            datefr(element.dp_date, null, "numeric"),
          mont_ht: parseFloat(element.ttc_paye) * -1,
          mont_ttc: parseFloat(element.ttc_paye) * -1,
        });
      });
      return items;
    },

    subTotal: function () {
      var total = 0;
      total = this.dp_detail.reduce((a, b) => a + (b["mont_ht"] || 0), 0);
      total = total.toFixed(2);
      return total;
    },

    taxTotal: function () {
      var total = 0;
      total = this.dp_detail.reduce((a, b) => a + (b["mont_tva"] || 0), 0);
      total = total.toFixed(2);
      return total;
    },
    grandTotal: function () {
      var total = 0;
      total = this.dp_detail.reduce((a, b) => a + (b["mont_ttc"] || 0), 0);
      total = total.toFixed(2);
      return total;
    },
  },
};
</script>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.text-green input {
  color: green !important;
}
.text-input-blue .v-text-field__slot input {
  color: #00f !important;
}
</style>
